@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

body {
  font-size: calc(12px + 0.2vw + 0.2vh) !important;
  overflow: hidden;
  background-color: #d3cdca !important;
  font-family: "Poppins", sans-serif !important;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  bottom: 100px !important;
  left: 5px !important;
  width: 50px !important;
  height: 100px !important;
}

@media (max-width: 600px) {
  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    bottom: 250px !important;
    left: 10px !important;
    width: 50px !important;
    height: 100px !important;
  }
}
.gm-style .gm-style-iw-c {
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px !important;
}
.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}
.gm-style .gm-style-iw-tc::after {
  background-color: rgba(0, 0, 0, 0.7);
}

.gm-ui-hover-effect {
  display: none !important;
}
.markerLabel {
  margin-top: -1.5vw;
  background: rgba(255, 255, 255, 0.8);
}

.form-control {
  padding: 0 !important;
  border-radius: 0 !important;
  width: fit-content !important;
}
.Mui-selected {
  background-color: rgb(199, 194, 192) !important;
  color: white !important;
}

/* width */
::-webkit-scrollbar {
  height: 10px;
  width: 5px;
  z-index: 1;
}

/* @media (max-width: 1023px) {
  ::-webkit-scrollbar {
    display: none;
  }
} */

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f07023;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f07023;
}

#tablePdf {
  background-color: white;
}

.clip_path {
  clip-path: polygon(98% 0%, 0% 0%, 48% 100%);
}

.infoBox {
  width: fit-content !important;
}

.sidebarActive {
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  z-index: 55;
  transition: 1s;
}

@media (min-width: 1023px) {
  .sidebarActive {
    right: 0;
    top: 0;
    bottom: 0;
    width: 350px;
    z-index: 55;
    transition: 1s;
  }
}

.sidebarCollapsed {
  z-index: 55;
  right: -100vw;
  width: 100vw;
  transition: 1s;
  top: 0;
  bottom: 0;
}

@media (min-width: 1023px) {
  .sidebarCollapsed {
    z-index: 55;
    right: -350px;
    width: 350px;
    transition: 1s;
    top: 0;
    bottom: 0;
  }
}

/* date range picker css*/

.DatetimeRangePicker .form-control {
  padding: 0;
  border-radius: 4px;
  padding: 4px 0 4px 4px !important;
}

/* linear progres bar */

.load-bar {
  position: relative;
  width: 100%;
  height: 6px;
  background-color: #f07023;
}
.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
}
.bar:nth-child(1) {
  background-color: #f07023;
  animation: loading 3s linear infinite;
}
.bar:nth-child(2) {
  background-color: #ff0023;
  animation: loading 3s linear 1s infinite;
}
.bar:nth-child(3) {
  background-color: #fdba2c;
  animation: loading 3s linear 2s infinite;
}
@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}

.infoBox img {
  display: none;
}

.clip-path-reverse {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.active {
  background-color: rgb(250, 130, 18) !important;
  color: white;
  padding: 4px;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50%;
}

.rdtPicker {
  position: fixed !important;
  width: fit-content !important;
}

.analysis th {
  padding: 0 10px 0 10px;
  border-bottom: 2px solid black;
  font-weight: 500;
}

.analysis td {
  padding: 0 10px 0 10px;
  border-bottom: 2px solid black;
  font-weight: 500;
}

.toast-position {
  top: 100px !important;
}

.__floater {
  margin-top: 35px !important;
}
